<template>
  <v-app class="setting-main-div">
    <acc-settings />
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    AccSettings: () => import("@/components/accountsettings/AccSettings"),
  },
  data() {
    return {
      showAccSettings: true,
    };
  },
  computed: {
    ...mapState("custom", ["selected_company", "check_trial_period_status"]),
  },
};
</script>

<style scoped>
.setting-main-div {
  padding-left: 35px;
  padding-right: 35px;
}

@media (max-width: 480px) {
  .setting-main-div {
    padding: 0px !important;
  }
  .btn-row .save-setting-btn {
    width: 40% !important;
  }
}
@media (max-width: 375px) {
  .btn-row .save-setting-btn {
    width: 40% !important;
  }
}
</style>
